import Link from 'components/Link';
import React, { useCallback, useEffect, useRef } from 'react';
import LayoutContainer from 'components/LayoutContainer';
import tw from 'twin.macro';
import { IMG_LINK_LIST } from 'components/constant';
import { graphql, useStaticQuery } from 'gatsby';

interface HeroTitleProps {
  title: string;
}

const BackgroundVideo = tw.video`
  absolute z-[-1] w-full h-full object-cover
`;
const TextWrapper = tw.div`
  px-[30px] lg:px-0 z-[1] md:w-[648px] flex flex-col gap-4 text-accent_grey_2 justify-center md:h-[calc(100vh_-_85px)] h-[calc(100vh_-_170px)]

`;
const StyledLink = tw(Link)`
  z-[1] text-accent_blue_1 px-3 py-2 bg-complimentary_yellow hover:bg-white hover:text-accent_blue_1 w-fit
`;
const Description = tw.h1`
  text-[inherit] text-[14px] leading-[150%] lg:text-[18px] font-[400] z-[1] m-0 
`;

const HeroTitle = ({ title }: HeroTitleProps) => {
  return (
    <p className="whitespace-pre-line text-accent_grey_2 z-[1] text-[36px] font-[600] lg:text-[64px] leading-[120%] lg:leading-[100%] m-0  ">
      {title}
    </p>
  );
};

const LandingHero = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiLandingPage {
        nodes {
          HeroMainBannerHeader
          HeroMainBannerDescription
          HeroMainBannerCTAButtonLabel
          HeroMainBannerCTAButtonLink
          HeroMainBannerBackgroundVideo {
            url
          }
          HeroMainBannerBackgroundImage {
            url
          }
        }
      }
    }
  `);
  const {
    HeroMainBannerHeader,
    HeroMainBannerDescription,
    HeroMainBannerCTAButtonLabel,
    HeroMainBannerCTAButtonLink,
    HeroMainBannerBackgroundVideo: { url: videoUrl },
    HeroMainBannerBackgroundImage: { url: backgroundImg },
  } = data.allStrapiLandingPage.nodes[0];

  const videoRef = useRef<HTMLVideoElement>(null);
  const heightOfWindow =
    typeof window !== 'undefined' ? window.innerHeight : 50;
  const handleScroll = useCallback(async () => {
    const show = window.scrollY < heightOfWindow;
    try {
      if (videoRef.current) {
        if (show) {
          await videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      }
    } catch (error) {}
  }, [videoRef.current]);
  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className="relative">
      <BackgroundVideo
        playsInline
        autoPlay
        loop
        muted
        ref={videoRef}
        style={{
          background: `url(${
            backgroundImg ? backgroundImg : IMG_LINK_LIST.landingPage.hero
          }) no-repeat center center/cover`,
        }}
      >
        <source src={videoUrl} type="video/mp4" />
      </BackgroundVideo>

      <LayoutContainer>
        <TextWrapper>
          <HeroTitle title={HeroMainBannerHeader} />
          <Description>{HeroMainBannerDescription}</Description>
          <StyledLink to={HeroMainBannerCTAButtonLink}>
            {HeroMainBannerCTAButtonLabel}
          </StyledLink>
        </TextWrapper>
      </LayoutContainer>
    </div>
  );
};

export default LandingHero;
